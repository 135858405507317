<template>
  <div
    class="data-card"
    :class="{ card: listType == 'card', list: listType == 'list' }"
    @click="jump"
  >
    <div class="tag" v-show="listType == 'card'">
      <span class="green" v-if="dataSource.progress == 100">
        {{ $t("CM_Completed")
        }}<!-- 已完成 -->
      </span>
      <span class="red" v-else>
        {{ $t("CM_Unfinished")
        }}<!-- 未完成 -->
      </span>
    </div>
    <div class="cover">
      <img :src="dataSource.coverImg" alt="cover" />
    </div>
    <div class="detail">
      <h5>
        <a-popover trigger="hover">
          <template #content>
            <div class="popover-content">
              {{ dataSource.courseName }}
            </div>
          </template>
          {{ dataSource.courseName }}
        </a-popover>
      </h5>
      <div class="condition mt12" v-show="listType == 'list'">
        {{ $t("CM_CCondition") }}：
        <!-- 完成条件 -->
        <div class="checkbox">
          <a-checkbox :checked="dataSource.sectionProgress == 100">
            {{ $t("chapter_completion")
            }}<!-- 章节完成 -->
          </a-checkbox>
        </div>
        <div class="checkbox" v-if="dataSource.isHaveHomework">
          <a-checkbox :checked="dataSource.isCompletedHomework">
            {{ $t("homework_after_class")
            }}<!-- 课后作业 -->
          </a-checkbox>
        </div>
        <div class="checkbox" v-if="dataSource.isHaveExam">
          <a-checkbox :checked="dataSource.isCompletedExam">
            {{ $t("after_class_exam")
            }}<!-- 课后考试 -->
          </a-checkbox>
        </div>
        <div class="checkbox" v-if="dataSource.isHaveQuestionnaire">
          <a-checkbox :checked="dataSource.isCompletedQuestionnaire">
            {{ $t("class_assessment")
            }}<!-- 课程评估 -->
          </a-checkbox>
        </div>
        <div class="checkbox" v-else-if="dataSource.isHaveStar">
          <a-checkbox :checked="dataSource.isCompletedStar">
            {{ $t("star_review")
            }}<!-- 星评 -->
          </a-checkbox>
        </div>
      </div>
      <div class="row mt12">
        {{ $t("Pub_Tab_LastStudy") }}：{{ dateFormat(dataSource.lastTime) }}
        <!-- 上次学习 -->
      </div>
      <div class="rate mt12">
        <span v-show="listType == 'list'"
          >{{ $t("course.total_progress") }}：<!-- 总进度 --></span
        >
        <a-progress
          size="small"
          strokeColor="#468CE3"
          :percent="dataSource.progress"
        >
          <template #format>
            <div class="rate-format">{{ dataSource.progress }}%</div>
          </template>
        </a-progress>
      </div>
    </div>
    <div class="btns" v-show="listType == 'list'">
      <div class="btn">
        {{ $t("view_detail") }}
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { dateFormat } from "@/utils/tools";
export default {
  name: "CourseCard",
  props: {
    newWindow: {
      type: Boolean,
      default: false,
    },
    listType: {
      type: String,
      default: "card",
    },
    dataSource: {
      type: Object,
      default: {},
    },
  },
  setup(props) {
    const router = useRouter();

    const jump = () => {
      if (props.newWindow) {
        window.open(
          `/course/detail?ddtab=true&id=${props.dataSource.courseId}`
        );
      } else {
        router.push({
          path: "/course/detail",
          query: {
            id: props.dataSource.courseId,
          },
        });
      }
    };

    return {
      dateFormat,
      jump,
    };
  },
};
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
